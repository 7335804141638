input:invalid {
  box-shadow: 0 0 0 0.25rem rgb(253 13 13 / 25%)!important;
}

.bg-overheal {background-color: rgb(25, 135, 84, .2)!important}
.bg-bloodied {background-color: rgb(255, 193, 7, .2)!important}
.bg-dead {background-color: rgb(220 53 69 / .2)!important}


.statblock table { margin-top: 1rem;}
.statblock p { margin-bottom: .2rem;}
